import { render, staticRenderFns } from "./viewRecords.vue?vue&type=template&id=70de280b&scoped=true&"
import script from "./viewRecords.vue?vue&type=script&lang=js&"
export * from "./viewRecords.vue?vue&type=script&lang=js&"
import style0 from "./viewRecords.vue?vue&type=style&index=0&id=70de280b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70de280b",
  null
  
)

export default component.exports